import React, { Component } from "react";
import axios from "axios";
import LogoDirlantas from "../assets/logo-dir-lantas-polri@2x.png";
import "./EmailResponse.css";
import ImageAlreadyConfirmed from "../assets/email-already-confirmed.svg";
import ImageHasBeenConfirmed from "../assets/email-has-been-confirmed.svg";
import ImageRejected from '../assets/email-rejected.svg';

export default class EmailResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailKey: "",
      isSuccess: false,
      loading: true,
      invalidKey: true,
    };
  }

  validatingEmail = (key) => {
    let formdata = new FormData();
    formdata.append("EmailKey", key);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}access/email/validation`,
        formdata,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false, isSuccess: true });
      })
      .catch((err) => {
        this.setState({ loading: false, isSuccess: false });
      });
  };

  componentDidMount() {
    const queryP = new URLSearchParams(this.props.location.search);
    let key = queryP.get("key");
    if (key) {
      this.setState({ emailKey: key });
      this.validatingEmail(key);
    }
  }

  conditionalTemplate() {
    let { loading, isSuccess, invalidKey } = this.state;

    if (loading == false) {
      if (isSuccess == true) {
        return (
          <React.Fragment>
            <img
              src={ImageHasBeenConfirmed}
              className="confirmed-image"
              alt="has-been-confirmed"
            />
            <div className="heading heading-1">EMAIL SUKSES DIKONFIRMASI</div>
            <div className="heading heading-2">Selamat </div>
            <div className="heading heading-3">
              Sekarang Anda sudah dapat menggunakan layanan Digital Korlantas seperti Registrasi SIM Online, Perpanjang SIM, Pendaftaran Pengesahan STNK, dan layanan lainnya.
            </div>
          </React.Fragment>
        );
      }
      if (isSuccess == false && invalidKey == false) {
        return (
          <React.Fragment>
            <img
              src={ImageRejected}
              style={{ height: '220px' }}
              className="confirmed-image"
              alt="rejected"
            />
            <div className="heading heading-1">Ups! Verifikasi Email Gagal</div>
            <div className="heading heading-2">Yuk, Coba Lagi </div>
            <div className="heading heading-3">
              Pastikan koneksi internet Anda stabil. Lalu klik kembali tautan "Aktivasi Akun Saya" yang kami kirim sebelumnya ke email Anda
            </div>
          </React.Fragment>
        );
      }
      if (isSuccess == false && invalidKey == true) {
        return (
          <React.Fragment>
            <img
              src={ImageRejected}
              style={{ height: '220px' }}
              className="confirmed-image"
              alt="rejected"
            />
            <div className="heading heading-1">Ups! Verifikasi Email Gagal</div>
            <div className="heading heading-2">Yuk, Coba Lagi </div>
            <div className="heading heading-3">
              Pastikan koneksi internet Anda stabil. Lalu klik kembali tautan "Aktivasi Akun Saya" yang kami kirim sebelumnya ke email Anda
            </div>
          </React.Fragment>
        );
      }
    }
  }

  render() {
    let { loading, isSuccess } = this.state;
    return (
      <div className="email-response">
        <div>
          <img src={LogoDirlantas} alt="logo" className="logo-image" />
        </div>
        {this.conditionalTemplate()}
      </div>
    );
  }
}
